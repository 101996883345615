<template>
  <v-container fluid>
    <!-- Titre -->
    <v-row class="justify-center text-center">
      <v-toolbar
        class="mx-3 my-2 gmiddleblue"
        :dark="this.$store.state.darkTheme ? true:false"
      >
        <v-toolbar-title>
          Select your profile
        </v-toolbar-title>
      </v-toolbar>
    </v-row>

    <!-- Choix du profil -->
    <v-row class="justify-center text-center">
      <!-- Profil type AGALAN -->
      <v-col>
        <v-card
          class="justify-center transparent"
          raised
          :dark="this.$store.state.darkTheme ? true:false"
        >
          <v-card-title class="justify-center mb-4">
            AGALAN members
            &thinsp;
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  far fa-question-circle
                </v-icon>
              </template>
              The Agalan account is an IT account at the level of the UGA (Université Grenoble Alpes). <br>
              It allows you to access all of the university's services and its components.
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            I have an AGALAN account.
          </v-card-text>
          <v-card-text>
            Your account will be created from your AGALAN information. <br>
            You will use your AGALAN login/password to log into the Perseus portal.
          </v-card-text>
          <v-card-text>
            <v-btn
              :dark="this.$store.state.darkTheme ? true:false"
              x-large
              :height="this.$store.state.windowH / 4"
              class="gmiddleblue my-4 overflow-x-hidden"
              @click="setAgalan"
            >
              <v-row>
                <v-col cols="12">
                  Create account from AGALAN
                </v-col>
                <v-col cols="12">
                  <v-icon x-large>
                    fas fa-sign-in-alt
                  </v-icon>
                </v-col>
              </v-row>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Profil type externe -->
      <v-col>
        <v-card
          class="justify-center transparent"
          raised
          :dark="this.$store.state.darkTheme ? true:false"
        >
          <v-card-title class="justify-center mb-4">
            Extern
          </v-card-title>
          <v-card-text>
            I don't have an AGALAN account.
          </v-card-text>
          <v-card-text>
            Your will have an extern account. You will not be able to create projects. <br>
            The "-ext" suffix will be added to the Perseus login  you will to create.
          </v-card-text>
          <v-card-text>
            <v-btn
              :dark="this.$store.state.darkTheme ? true:false"
              x-large
              :height="this.$store.state.windowH / 4"
              class="gmiddleblue my-4"
              @click="unsetAgalan"
            >
              <v-row>
                <v-col cols="12">
                  Create new account
                </v-col>
                <v-col cols="12">
                  <v-icon x-large>
                    fas fa-user-plus
                  </v-icon>
                </v-col>
              </v-row>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Logo DRICAD -->
    <v-row :class="(this.$store.state.windowW<700)?'my-12 justify-center':''">
      <v-btn
        text
        color="white"
        x-large
        exact
        :absolute="this.$store.state.windowW<700?false:true"
        :bottom="this.$store.state.windowW<700?false:true"
        class="my-2 mx-2"
        height="70px"
        href="https://gricad.gricad-pages.univ-grenoble-alpes.fr/web/publicweb/index.html"
        target="_blank"
      >
        <!-- :style="this.$store.state.darkTheme?'filter: invert(1)':'filter: invert(0)'" -->
        <v-img
          v-if="this.$store.state.darkTheme"
          src="@/assets/logo_gricad_horizontal_dark.png"
          max-width="230px"
        />
        <v-img
          v-else
          src="@/assets/logo_gricad_horizontal.png"
          max-width="230px"
        />
      </v-btn>
    </v-row>

    <v-row class="my-12" />
  </v-container>
</template>

<script>
  import config from '@/config'

  export default {
    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,
      // Initialisation des v-model
      dialog: false,
      test: false,
      option: '',
    }),

    computed: {
      style_centerblock () {
        return {
          // 'height': `url(${this.$store.state.windowH})`,
          // 'height': `${this.$store.state.windowH}`,
          height: `${this.$store.state.windowH - 400}px`,
          'background-color': 'transparent',
        }
      },
      style_bigbtn () {
        return {
          height: `${this.$store.state.windowH / 4}px`,
          width: `${this.$store.state.windowW / 4}px`,
          'min-width': '300px',
          'background-color': this.blue,
          color: 'white',
        }
      },
      style_col () {
        return {
          height: `${(2 * this.$store.state.windowH / 3) - 100}px`,
        }
      }
    },

    methods: {
      setAgalan () {
        this.$store.state.agalan = true
        this.$router.push('/create-account/portal-agalan')
      },
      unsetAgalan () {
        this.$store.state.agalan = false
        this.$router.push('/create-account/form')
      },
    },

  }
</script>

<style lang="scss">

</style>
